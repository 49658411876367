<template>
  <div style="color: #000;height: 100%; background: #f5f5f5;">
    <div class="boy">
      <div class="xlc">您还没有设置修理厂！</div>
      <div class="an">
        <van-button plain type="default" size="small" @click="quxiao">取消</van-button>
        <van-button plain type="danger" size="small" @click="gotianjia">去填加</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: [],
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    quxiao() {
      WeixinJSBridge.call("closeWindow");
    },
    gotianjia() {
      this.$router.push({
        path: "commonadd",
          query: {
          isok: false
        }
      });
    }
  },
  created() {},
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.boy {
  margin: 0 12px;
  background: #fff;
  height: 200px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.xlc {
  padding-top: 80px;
}
.an {
  padding-top: 40px;
}
.van-button {
  margin: 0 12px;
}
</style>